<template>
  <div class="newspapership-page fade-up">
    <div class="dashboard-table" v-loading="isLoading">
      <div class="dashboard-table__header">
        <div
          class="dashboard-table__header-actions"
          :class="{ visible: multipleSelection.length }"
        >
          <div class="selected">{{ multipleSelection.length }} танланган</div>
          <el-button
            type="danger"
            icon="el-icon-delete"
            @click="deleteSelectedNewspapers()"
          >
            Ўчириш
          </el-button>
        </div>
        <div class="left">
          <h2>"Postda" газеталар</h2>
        </div>
        <div class="right">
          <el-select
            v-model="selectedYear"
            placeholder="Йил"
            style="margin-right: 15px;"
          >
            <el-option
              v-for="year in years"
              :key="year"
              :label="year"
              :value="year"
            >
            </el-option>
          </el-select>
          <el-button
            type="primary"
            icon="el-icon-plus"
            @click="dialogNewVisible = true"
          >
            Янги қушиш
          </el-button>
        </div>
      </div>
      <div class="dashboard-table__body">
        <el-table
          ref="dashboardTable"
          style="width: 100%"
          :data="newspapers"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55" />
          <el-table-column prop="year" label="Йил">
            <template slot-scope="scope">{{
              scope.row.year + " йил"
            }}</template>
          </el-table-column>
          <el-table-column prop="number" label="Рақам"></el-table-column>
          <el-table-column label="Амаллар" width="120">
            <template slot-scope="scope">
              <el-button
                size="mini"
                icon="el-icon-edit"
                @click="editNewspaperHandler(scope.row)"
              />
              <el-button
                size="mini"
                type="danger"
                icon="el-icon-delete"
                @click.stop="deleteNewspaper(scope.row)"
              />
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-dialog
      class="dialog-sm"
      width="350px"
      title="Янги газета"
      :visible.sync="dialogNewVisible"
    >
      <el-card class="fade-up">
        <div class="el-card-header">
          <drop-file-input
            v-model="newNewspaper.image"
            :style="{ marginBottom: '10px' }"
          />
          <el-input-number
            v-model="newNewspaper.number"
            :min="1"
            placeholder="Рақам"
            :style="{ marginBottom: '10px', width: '100%' }"
          ></el-input-number>
          <el-input-number
            v-model="newNewspaper.year"
            :min="2009"
            placeholder="Йил"
            :max="new Date().getFullYear()"
            :style="{ marginBottom: '10px', width: '100%' }"
          ></el-input-number>
        </div>
      </el-card>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogNewVisible = false">Бекор қилиш</el-button>
        <el-button
          type="primary"
          :disabled="Object.values(newNewspaper).some(v => !v)"
          @click="addNewspaper"
        >
          Қушиш
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      class="dialog-sm"
      width="350px"
      title="Газетани тахрирлаш"
      :visible.sync="dialogEditVisible"
    >
      <el-card class="fade-up">
        <div class="el-card-header">
          <drop-file-input
            v-model="editNewspaper.image"
            :style="{ marginBottom: '10px' }"
          />
          <el-input-number
            v-model="editNewspaper.number"
            :min="1"
            placeholder="Рақам"
            :style="{ marginBottom: '10px', width: '100%' }"
          ></el-input-number>
          <el-input-number
            v-model="editNewspaper.year"
            :min="2009"
            placeholder="Йил"
            :max="new Date().getFullYear()"
            :style="{ marginBottom: '10px', width: '100%' }"
          ></el-input-number>
        </div>
      </el-card>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogEditVisible = false">Бекор қилиш</el-button>
        <el-button
          type="primary"
          :disabled="Object.values(editNewspaper).some(v => !v)"
          @click="updateNewspaper"
        >
          Сақлаш
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import DropFileInput from "@/components/DropFileInput";

export default {
  components: {
    DropFileInput
  },
  data: () => ({
    isLoading: false,
    dialogNewVisible: false,
    dialogEditVisible: false,
    selectedYear: "",
    years: [],
    newNewspaper: {
      image: null,
      number: "",
      year: ""
    },
    editNewspaper: {
      image: null,
      number: "",
      year: ""
    },
    multipleSelection: []
  }),
  computed: {
    newspapers() {
      return this.$store.state.newspaperModule.newspapers;
    }
  },
  watch: {
    selectedYear(year) {
      const loading = this.$loading({
        lock: true
      });
      this.$store
        .dispatch("fetchNewspapers", { year })
        .finally(() => loading.close());
    }
  },
  created() {
    for (let i = 2009; i <= new Date().getFullYear(); i++) {
      this.years.push(String(i));
    }
    const loading = this.$loading({
      lock: true
    });
    this.$store.dispatch("fetchNewspapers").finally(() => loading.close());
  },
  methods: {
    updateNewspaper() {
      if (this.isLoading) return;

      this.isLoading = true;
      this.dialogEditVisible = false;
      const formData = new FormData();
      const data = { ...this.editNewspaper };
      delete data.image;
      formData.append("data", JSON.stringify(data));
      if (
        typeof this.editNewspaper.image !== "string" &&
        this.editNewspaper.image !== null
      ) {
        formData.append("files.image", this.editNewspaper.image);
      }
      this.$store
        .dispatch("updateNewspaper", {
          data: formData,
          id: this.editNewspaper.id
        })
        .then(() => {
          this.$message({
            type: "success",
            message: "Сақланди"
          });
        })
        .finally(() => {
          this.editNewspaper.image = null;
          this.editNewspaper.number = 1;
          this.editNewspaper.year = "";
          this.isLoading = false;
        });
    },
    deleteNewspaper(row) {
      this.$confirm("Ўчириш амалини тасдиқлайсизми?", "Диққат!", {
        confirmButtonText: "Ха",
        cancelButtonText: "Бекор қилиш",
        type: "warning"
      })
        .then(() => {
          this.isLoading = true;
          this.$store
            .dispatch("deleteNewspaper", row.id)
            .then(() => {
              this.$message({
                type: "success",
                message: "Ўчирилди"
              });
            })
            .finally(() => (this.isLoading = false));
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Бекор қилинди"
          });
        });
    },
    deleteSelectedNewspapers() {
      this.$confirm("Ўчириш амалини тасдиқлайсизми?", "Диққат!", {
        confirmButtonText: "Ха",
        cancelButtonText: "Бекор қилиш",
        type: "warning"
      })
        .then(() => {
          this.isLoading = true;
          Promise.all(
            this.multipleSelection.map(r => {
              this.$store.dispatch("deleteNewspaper", r.id);
            })
          )
            .then(() => {
              this.$message({
                type: "success",
                message: "Ўчирилди"
              });
            })
            .finally(() => (this.isLoading = false));
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Бекор қилинди"
          });
        });
    },
    addNewspaper() {
      if (this.isLoading) return;

      this.isLoading = true;
      this.dialogNewVisible = false;
      const data = { ...this.newNewspaper };
      delete data.image;
      const formData = new FormData();
      formData.append("data", JSON.stringify(data));
      formData.append("files.image", this.newNewspaper.image);
      this.$store
        .dispatch("addNewspaper", formData)
        .then(() => {
          this.$message({
            type: "success",
            message: "Қушилди"
          });
        })
        .finally(() => {
          console.log("delete");
          this.newNewspaper.image = null;
          this.newNewspaper.number = 1;
          this.newNewspaper.year = "";
          this.isLoading = false;
        });
    },
    editNewspaperHandler(item) {
      this.editNewspaper = { ...item };
      this.editNewspaper.image = this.$baseUrl + item.image.url;
      this.dialogEditVisible = true;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    }
  }
};
</script>

<style lang="scss" scoped>
.newspapership-page {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}
</style>
